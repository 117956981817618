import { createRoot } from "react-dom/client";
import {Home} from './pages/Home';
import {Capabilities} from './pages/Capabilities';
import {TargetScore} from './pages/TargetScore';
import {Measurement} from './pages/Measurement';
import {Assessment} from './pages/Assessment';
import {Auth} from './pages/Auth';
import {Admin} from './pages/Admin';
import {Login} from './pages/Login';
import {ForgotPassword} from './pages/ForgotPassword';
import {ShareSummary} from './pages/ShareSummary';
import {FullProfile} from './pages/FullProfile';
import { Provider } from 'react-redux'
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import store from './store';
import React from "react";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import {getAssessment, setLoading} from "./reducers/assessment";

const searchClient = algoliasearch('64LMXTN0XN', '79c4a700b51a2af70dd807fb5a61decb');

const router = createBrowserRouter([
    {
        index: true,
        path: "/",
        element: <Home />,
    },
    {
        path: "/full-profile",
        element: <FullProfile />,
    },
    {
        path: "/auth/linkedin",
        element: <Auth />,
    },
    {
        path: "/assessment",
        element: <Assessment />,
    },
    {
        path: "/admin",
        element: <Admin />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />,
    },
    {
        path: "/capabilities",
        element: <Capabilities />,
    },
    {
        path: "/target-score",
        element: <TargetScore />,
    },
    {
        path: "/measurement",
        element: <Measurement />,
    },
    {
        path: "/summary/:uuid",
        element: <ShareSummary />,
        loader: ({params}) => {
            store.dispatch(setLoading(true));
            store.dispatch(getAssessment( params.uuid ));

            return {loading: true};
        }
    }
]);

const App = () => {
    return (
        <Provider store={store}>
            <InstantSearch searchClient={searchClient} indexName="prod_wp-capabilities">
                <RouterProvider className={'absolute'} router={router} />
            </InstantSearch>
        </Provider>
    );
}

createRoot(document.getElementById("dataApp")).render(<App />);